import React from 'react';
import {goBack, goCS} from '@/utils';
import {BackIcon} from '../svg/back-icon';
import {ServerIcon} from '../svg/server-icon';
import './invitation.css';

const NormalNav: React.FC<{title: string}> = ({title}) => {
  return (
    <div className="normal-nav">
      <div className="back-icon" onClick={goBack}>
        <BackIcon />
      </div>
      <span className="normal-nav-title">{title}</span>
      <div className="back-icon" onClick={goCS}>
        <ServerIcon />
      </div>
    </div>
  );
};

export default NormalNav;
